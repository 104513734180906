import React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import TopBanner from "../components/notfound/topbanner";
const ogImage = {
  ogImage: "https://graphql-engine-cdn.hasura.io/assets/main-site/card.png",
};
const canonicalUrl = "https://hasura.io/404/";

const NotFoundPage = props => (
  <Layout location={props.location}>
    <Seo
      title="404: Not found"
      description="404: Not found"
      meta={ogImage}
      canonicalLink={canonicalUrl}
    />
    <TopBanner />
  </Layout>
);

export default NotFoundPage;
