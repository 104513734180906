// import { Link } from "gatsby"
import React from "react";
// import PropTypes from "prop-types"
import "./styles.scss";
import notFound from "./images/not-found.jpg";
const TopBanner = () => {
  return (
    <section>
      <div className="container-fluid noPadd">
        <div className="notFoundWrapper">
          <img className={"img-responsive"} src={notFound} alt="Page not found" />
        </div>
      </div>
    </section>
  );
};

export default TopBanner;
